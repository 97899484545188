angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('signproxy', {
            url: '/sign/:type/:token',
            views: {
                "content": {
                    controller: 'SignProxyCtrl',
                    template: '<div></div>'
                }
            },
            data: {
                pageTitle: 'Sign document'
            }
        });
    })
    .controller('SignProxyCtrl', function($rootScope, $scope, signatureService, $stateParams, $timeout, $translate, companyService, $state) {

        signatureService.retrieveFirmaByToken($stateParams.token).then(function (response) {
            $state.go('sign', {
                token: $stateParams.token,
                company: response.data.signature.user.company.id,
                type: response.data.signature.type
            });
        }, function (error) {
            swal($translate.instant('Algo ha ido mal'), $translate.instant("Esta firma ya no está disponible"), "error");
            $timeout(function () {
                window.location = "https://" + $rootScope.dominio;
            }, 5000);
        });

    });
